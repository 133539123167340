<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import '@/assets/images/marketing/screenshots/Notifications_mobile.png';
import Action from '@/modules/marketing/MarketingAction.vue';
import { Slider, SliderItem } from 'vue-easy-slider';

@Component({
  components: {
    Action,
    Slider,
    SliderItem,
  },
  layout: 'default',
  metaInfo: {
    title: 'Features ',
  },
})
export default class Features extends Vue {}
</script>

<template>
  <main>
    <section class="py-24 md:pt-24 md:pb-12">
      <div class="bg-white">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div class="container px-4 mx-auto">
            <div class="max-w-4xl mx-auto mb-20 text-center">
              <h1 class="leading-6 text-lg text-red-600 font-semibold tracking-wide uppercase">Features</h1>
              <h2 class="my-3 md:text-4xl text-gray-900 font-bold font-heading"
                >Invite guests to check-in on their<br />
                own mobile device, prior to arrival
              </h2>
              <p class="text-gray-500"
                >Your guests can provide all of their personal information, upload pictures of their identity documents, and sign the
                agreements you require, prior to arrival, all on their own mobile device through Check-In Buddy’s user-friendly platform.
                This allows you to have access to the guest’s information prior to check-in, from anywhere in the world, making visitor
                management smooth and easy.</p
              >
            </div>

            <!--Slider-->
            <div class="slider-wrap">
              <Slider class="features-slider" height="280px" animation="fade" :duration="5000" :speed="1000">
                <SliderItem>
                  <div class="relative max-w-6xl mx-auto mb-20">
                    <div class="relative">
                      <img src="@/assets/images/macbook.png" alt="mockup" />
                      <div class="absolute" style="top: 5.8%; left: 14.6%; width: 72.8%; height: 76.7%">
                        <img
                          class="object-cover w-full h-full hoverZoomLink"
                          src="@/assets/images/marketing/screenshots/organisation_dashboard.jpg"
                          alt="organisation_dashboard_mockup"
                        />
                      </div>
                    </div>
                  </div>
                </SliderItem>

                <SliderItem>
                  <div class="relative max-w-6xl mx-auto mb-20">
                    <div class="relative">
                      <img src="@/assets/images/macbook.png" alt="mockup" />
                      <div class="absolute" style="top: 5.8%; left: 14.6%; width: 72.8%; height: 76.7%">
                        <img
                          class="object-cover w-full h-full hoverZoomLink"
                          src="@/assets/images/marketing/screenshots/new_accommodation.jpg"
                          alt="organisation_dashboard_mockup"
                        />
                      </div>
                    </div>
                  </div>
                </SliderItem>

                <SliderItem>
                  <div class="relative max-w-6xl mx-auto mb-20">
                    <div class="relative">
                      <img src="@/assets/images/macbook.png" alt="mockup" />
                      <div class="absolute" style="top: 5.8%; left: 14.6%; width: 72.8%; height: 76.7%">
                        <img
                          class="object-cover w-full h-full hoverZoomLink"
                          src="@/assets/images/marketing/screenshots/new_booking.jpg"
                          alt="organisation_dashboard_mockup"
                        />
                      </div>
                    </div>
                  </div>
                </SliderItem>

                <SliderItem>
                  <div class="relative max-w-6xl mx-auto mb-20">
                    <div class="relative">
                      <img src="@/assets/images/macbook.png" alt="mockup" />
                      <div class="absolute" style="top: 5.8%; left: 14.6%; width: 72.8%; height: 76.7%">
                        <img
                          class="object-cover w-full h-full hoverZoomLink"
                          src="@/assets/images/marketing/screenshots/bookings.jpg"
                          alt="organisation_dashboard_mockup"
                        />
                      </div>
                    </div>
                  </div>
                </SliderItem>
              </Slider>
            </div>

            <div class="mt-10">
              <dl class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
                <div class="relative">
                  <dt>
                    <div class="absolute flex items-center justify-center h-12 w-12 rounded bg-red-500 text-white">
                      <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M8 9l3 3-3 3m5 0h3M5 20h14a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                        ></path>
                      </svg>
                    </div>
                    <p class="ml-16 text-lg leading-6 font-medium text-gray-900">Digital signatures and safe storage</p>
                  </dt>
                  <dd class="mt-2 ml-16 text-base text-gray-500">
                    Guests sign necessary indemnity forms and terms &amp; conditions through the app. These agreements are stored securely
                    in the cloud under each guest’s profile. You can access this data at any time.
                  </dd>
                </div>

                <div class="relative">
                  <dt>
                    <div class="absolute flex items-center justify-center h-12 w-12 rounded bg-red-500 text-white">
                      <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M9 14l6-6m-5.5.5h.01m4.99 5h.01M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16l3.5-2 3.5 2 3.5-2 3.5 2zM10 8.5a.5.5 0 11-1 0 .5.5 0 011 0zm5 5a.5.5 0 11-1 0 .5.5 0 011 0z"
                        ></path>
                      </svg>
                    </div>
                    <p class="ml-16 text-lg leading-6 font-medium text-gray-900">COVID-19 screening and temperature checks</p>
                  </dt>
                  <dd class="mt-2 ml-16 text-base text-gray-500">
                    Choose to have your guests complete our integrated COVID-19 health questionnaire, developed in line with the TBCSA
                    Tourism Industry Standard Protocols, on the day of arrival and load daily temperature checks to their profiles.
                  </dd>
                </div>

                <div class="relative">
                  <dt>
                    <div class="absolute flex items-center justify-center h-12 w-12 rounded bg-red-500 text-white">
                      <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z"
                        ></path>
                      </svg>
                    </div>
                    <p class="ml-16 text-lg leading-6 font-medium text-gray-900">Cloud dashboards for powerful reporting</p>
                  </dt>
                  <dd class="mt-2 ml-16 text-base text-gray-500">
                    Add and manage multiple establishments and upcoming bookings, and monitor your guests daily to see who is still to
                    arrive, who is currently checked-in, and who is checking-out.
                  </dd>
                </div>

                <div class="relative">
                  <dt>
                    <div class="absolute flex items-center justify-center h-12 w-12 rounded bg-red-500 text-white">
                      <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                        ></path>
                      </svg>
                    </div>
                    <p class="ml-16 text-lg leading-6 font-medium text-gray-900">Customise and share your info with guests</p>
                  </dt>
                  <dd class="mt-2 ml-16 text-base text-gray-500">
                    Load details about your establishment, like contact information, directions, and facilities. Provide your guests with
                    all the particulars they require prior to arrival and during their stay.
                  </dd>
                </div>
                <div class="relative">
                  <dt>
                    <div class="absolute flex items-center justify-center h-12 w-12 rounded bg-red-500 text-white">
                      <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                        ></path>
                      </svg>
                    </div>
                    <p class="ml-16 text-lg leading-6 font-medium text-gray-900">Get daily or weekly status updates</p>
                  </dt>
                  <dd class="mt-2 ml-16 text-base text-gray-500"
                    >Receive an email that lists all the guests arriving and leaving that day, all your pending check-in invitations, and
                    all guests that are checked in and checked out.</dd
                  >
                </div>

                <div class="relative">
                  <dt>
                    <div class="absolute flex items-center justify-center h-12 w-12 rounded bg-red-500 text-white">
                      <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                        ></path>
                      </svg>
                    </div>
                    <p class="ml-16 text-lg leading-6 font-medium text-gray-900">Top up on check-in credits as you go</p>
                  </dt>
                  <dd class="mt-2 ml-16 text-base text-gray-500">
                    During peak periods, you can purchase more check-in credits as and when you need them from the subscription section of
                    your account page.
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <section class="pt-24 pb-24"
      ><div class="container px-4 mx-auto">
        <div class="flex flex-wrap -mx-3">
          <div class="w-full md:w-1/2 lg:w-1/4 mb-12 px-3">
            <div class="text-center">
              <span class="inline-block p-5 mb-6 bg-red-600 text-white rounded-full">
                <svg class="w-12 h-12" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M8 9l3 3-3 3m5 0h3M5 20h14a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                  ></path></svg
              ></span>
              <h4 class="mb-3 text-lg font-semibold font-heading">Digital signatures and safe storage</h4>
              <p class="text-blueGray-400 leading-loose"
                >Guests sign necessary indemnity forms and terms &amp; conditions, through the app. These agreements are stored securely in
                the cloud under each guest’s profile. You can access this data at any time.
              </p>
            </div>
          </div>
          <div class="w-full md:w-1/2 lg:w-1/4 mb-12 px-3">
            <div class="text-center">
              <span class="inline-block p-5 mb-6 bg-red-600 text-white rounded-full">
                <svg class="h-12 w-12" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 14l6-6m-5.5.5h.01m4.99 5h.01M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16l3.5-2 3.5 2 3.5-2 3.5 2zM10 8.5a.5.5 0 11-1 0 .5.5 0 011 0zm5 5a.5.5 0 11-1 0 .5.5 0 011 0z"
                  ></path></svg>
                  </span>
              <h4 class="mb-3 text-lg font-semibold font-heading">COVID-19 screening and tempreture checks</h4>
              <p class="text-blueGray-400 leading-loose"
                >Your guests will be prompted to complete our integrated health check questionnaire upon arrival and daily thereafter.
                Developed in line with the TBCSA Tourism Industry Standard Protocols.</p
              >
            </div>
          </div>
          <div class="w-full md:w-1/2 lg:w-1/4 mb-12 px-3">
            <div class="text-center">
              <span class="inline-block p-5 mb-6 bg-red-600 text-white rounded-full">
                <svg class="w-12 h-12" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z"
                  ></path></svg
              ></span>
              <h4 class="mb-3 text-lg font-semibold font-heading">Cloud dashboards for powerful reporting</h4>
              <p class="text-blueGray-400 leading-loose"
                >Manage your establishments and upcoming bookings, know which guests are still to arrive, who is currently checked-in, and which guests are checking-out daily.</p
              >
            </div>
          </div>
          <div class="w-full md:w-1/2 lg:w-1/4 mb-12 px-3">
            <div class="text-center">
              <span class="inline-block p-5 mb-6 bg-red-600 text-white rounded-full">
                <svg class="w-12 h-12" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                  ></path></svg
              ></span>
              <h4 class="mb-3 text-lg font-semibold font-heading">Customise and share your information with guests</h4>
              <p class="text-blueGray-400 leading-loose"
                >Load details about your establishment, like contact details, directions and facilities. Give your guests all the information that they may need prior to arrival and during their stay.</p
              >
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <section class="py-12 md:py-20">
      <div class="container px-4 mx-auto">
        <div class="flex flex-wrap px-4">
          <div class="w-full md:w-5/12 lg:max-w-lg m-auto">
            <div class="flex h-full">
              <div class="m-auto">
                <div class="mb-8">
                  <h2 class="mb-6 text-4xl md:text-4xl text-gray-900 font-bold font-heading">Build a customisable check-in form</h2>
                  <p class="text-base text-gray-500"
                    >Customise your accommodation's check-in form at any time by toggling fields on and off. This gives you complete control
                    over what information you receive from your guests. While editing, you can preview how the form will appear to guests
                    when they check-in.</p
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="w-full md:w-7/12 px-4 mt-12 md:mt-0 order-last md:order-last">
            <div class="flex flex-row order-last md:order-last justify-center md:justify-last">
              <div class="relative">
                <img src="@/assets/images/macbook.png" alt="" />
                <div class="absolute" style="top: 5.8%; left: 14.6%; width: 72.8%; height: 77%">
                  <img
                    class="object-cover w-full h-full hoverZoomLink"
                    src="@/assets/images/marketing/screenshots/custom_form.jpg"
                    alt="organisation_dashboard_mockup"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="py-12 md:py-20">
      <div class="container px-4 mx-auto">
        <div class="flex flex-wrap px-4">
          <div class="w-full md:w-7/12 mt-12 md:mt-0 order-last md:order-first m-auto">
            <div class="flex flex-row order-last md:order-first justify-center md:justify-start">
              <div class="relative">
                <img src="@/assets/images/macbook.png" alt="" />
                <div class="absolute" style="top: 5.8%; left: 14.6%; width: 72.8%; height: 76.7%">
                  <img
                    class="object-cover w-full h-full hoverZoomLink"
                    src="@/assets/images/marketing/screenshots/qr_screen.jpg"
                    alt="organisation_dashboard_mockup"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="w-full md:w-5/12 lg:max-w-lg px-4">
            <div class="flex h-full">
              <div class="my-auto">
                <div class="mb-8">
                  <h2 class="mb-6 text-3xl md:text-4xl text-gray-900 font-bold font-heading">Front desk QR code for simplified check-in</h2>
                  <p class="text-base text-gray-500"
                    >Display your unique QR code at reception for walk-in guests, who have not yet checked-in, to scan on arrival. They can
                    simply check-in using Check-In Buddy’s digital, user-friendly platform, allowing for a safe and easy check-in.</p
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="py-12 md:py-20">
      <div class="container px-4 mx-auto">
        <div class="flex flex-wrap px-4">
          <div class="w-full md:w-5/12 lg:max-w-lg m-auto">
            <div class="flex h-full">
              <div class="m-auto">
                <div class="mb-8">
                  <h2 class="mb-6 text-4xl md:text-4xl text-gray-900 font-bold font-heading"
                    >Secure &amp; POPI-compliant cloud storage of data</h2
                  >
                  <p class="text-base text-gray-500"
                    >Access your data from anywhere in the world, spot trends, and produce reports. Find and export the exact information
                    you want when you need it. Back up business decisions, assist with COVID-19 tracing efforts, and satisfy audit requests
                    with easily accessible data.</p
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="w-full md:w-7/12 px-4 mt-12 md:mt-0 order-last md:order-last">
            <div class="flex flex-row order-last md:order-last justify-center md:justify-last">
              <div class="relative">
                <img src="@/assets/images/macbook.png" alt="" />
                <div class="absolute" style="top: 5.8%; left: 14.6%; width: 72.8%; height: 76.7%">
                  <img
                    class="object-cover w-full h-full hoverZoomLink"
                    src="@/assets/images/marketing/screenshots/guest_profile.jpg"
                    alt="organisation_dashboard_mockup"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <section class="py-12 md:py-20">
      <div class="container px-4 mx-auto">
        <div class="flex flex-wrap -mx-4">

          <div class="w-full md:w-1/3 lg:max-w-lg px-4">
            <div class="flex h-full">
              <div class="my-auto">
                <div class="mb-8">
                  <h2 class="mb-6 text-3xl md:text-4xl font-bold font-heading"
                    >Front Desk QR code for simplified check-in</h2
                  >
                  <p class="leading-loose text-blueGray-400"
                    >Display your unique QR code at reception for walk-in guests, who have not yet checked-in, to scan on arrival. They can
                    simply check-in using Check-In Buddy’s digital, user-friendly platform, allowing for a safe and easy check-in.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="w-full md:w-2/3 px-4 mt-12 md:mt-0 order-last md:order-first m-auto">
            <div class="flex flex-row order-last md:order-last justify-center md:justify-last">
              <div class="relative">
                <img src="@/assets/images/macbook.png" alt="" />
                <div class="absolute" style="top: 5.8%; left: 14.6%; width: 72.8%; height: 76.7%">
                  <img
                    class="object-cover w-full h-full hoverZoomLink"
                    src="@/assets/images/marketing/screenshots/guest_profile.jpg"
                    alt="organisation_dashboard_mockup"
                  />
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section> -->

    <Action />
  </main>
</template>

<style lang="css">
.slider-wrap {
  position: relative;
  z-index: 10;
}

.features-slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media only screen and (min-width: 500px) {
  .features-slider {
    height: 330px !important;
  }
}

@media only screen and (min-width: 600px) {
  .features-slider {
    height: 380px !important;
  }
}

@media only screen and (min-width: 768px) {
  .features-slider {
    height: 460px !important;
  }
}

@media only screen and (min-width: 1024px) {
  .features-slider {
    height: 580px !important;
  }
}

@media only screen and (min-width: 1150px) {
  .features-slider {
    height: 650px !important;
  }
}

@media only screen and (min-width: 1440px) {
  .features-slider {
    height: 700px !important;
  }
}

.slider-btn-right {
  background: none !important;
}

.slider-btn-left {
  background: none !important;
}
</style>
